import React, { useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./Components/Headers";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Support from "./Pages/Support/Support";
import Blogs from "./Pages/Blogs/Blogs";
import DetailsLayout from "./Pages/BlogDeatils/BlogDeatails";
import Delivery from "./Pages/Delivey/Delivery";
import DeliveryManTax from "./Pages/DeliveryMan/DeliveryManTax";
import LoginPage from "./Pages/Login/Login";
import Sidebar from "./Pages/Sidebar/Sidebars";
import NotFound from "./Pages/NotFound/NotFound";
import Resturants from "./Pages/Restaurants/Resturants";
import SupportDetails from "./Pages/SupportDetails/Support";
import HomeSlider from "./SidebarMenus/HomeSide/HomeSlider";
import HomeService from "./SidebarMenus/HomeSide/HomeService";
import HomeBecomeImage from "./SidebarMenus/HomeSide/HomeBecomeImage";
import HomeBecomeCard from "./SidebarMenus/HomeSide/HomeBecomeCard";
import HomeLearnMore from "./SidebarMenus/HomeSide/HomeLearnMore";
import HomeStatistics from "./SidebarMenus/HomeSide/HomeStatistics";
import AboutSlider from "./SidebarMenus/AboutSide/AboutSlider";
import AboutService from "./SidebarMenus/AboutSide/AboutService";
import AboutServiceDetails from "./SidebarMenus/AboutSide/AboutServiceDetails";
import OurTeam from "./SidebarMenus/AboutSide/OurTeam";
import MyComponent from "./SidebarMenus/AboutSide/Whyus";
import SupportSection from "./SidebarMenus/SupportSide/SupportSection";
import SupportSlider from "./SidebarMenus/SupportSide/SupportSlider";
import BlodService from "./SidebarMenus/BlogSide/BlodService";
import BlogSlider from "./SidebarMenus/BlogSide/BlodSlider";
import SupportDetailsHeader from "./SidebarMenus/SupportDetails/SupportDetailsHeader";
import BlogDetailsHeader from "./SidebarMenus/BlogDetailsSide/BlogDetailsHeader";
import BlogPosts from "./SidebarMenus/BlogDetailsSide/BlogPosts";
import HomeStatisticsTitle from "./SidebarMenus/HomeSide/HomeStatisticsTitle";
import RestauransSlider from "./SidebarMenus/RestaurantSide/RestauransSlider";
import ResturantsService from "./SidebarMenus/RestaurantSide/ResturantsService";
import Restaurantpartnerships from "./SidebarMenus/RestaurantSide/Restaurantpartnerships";
import ProtectedRoute from "./Pages/ProtectedRoutes/Protected";
import SupportFAQ from "./SidebarMenus/SupportSide/SupportFAQ";
import SupportDetailsFAQ from "./SidebarMenus/SupportDetails/SupportDetailsFAQ";
import PartnerShips from "./SidebarMenus/SupportSide/PartnerShips";
import BecomePartner from "./SidebarMenus/AboutSide/BecomePartner";
import TextEditor from "./Pages/Editor/Editor";
import FetchEditor from "./Pages/Editor/FetchEditor";
import Test from "./Pages/Test/Test";
import Servicess from "./Pages/Test/Servicess";
import EMuraabaha from "./Pages/Test/Murabaxa";
import Driverss from "./Pages/Test/Drivers";
import Lasts from "./Pages/Test/Lasts";
import ServicesPage from "./Pages/Services/Services";
import HomeFAQ from "./SidebarMenus/HomeSide/HomeFAQ";
import ServiceSlider from "./SidebarMenus/ServiceSide/ServiceSlider";
import OurServices from "./SidebarMenus/ServiceSide/OurServices";
import EburaabahaComponent from "./SidebarMenus/ServiceSide/Eburaabaha";
import ChooseOurService from "./SidebarMenus/ServiceSide/ChooseOurService";
import ServiceFAQ from "./SidebarMenus/ServiceSide/ServiceFAQ";

function Main() {
  const location = useLocation();

  // Determine if the current path is under /sidebar
  const isSidebarRoute = location.pathname.startsWith("/Sidebar");
  const hideNavbarPaths = [
    "/Sidebar",
    "/Sidebar/Last",
    "/Sidebar/homeslider",
    "/Sidebar/homeservice",
    "/Sidebar/homeimages",
    "/Sidebar/homecards",
    "/Sidebar/homelearnmore",
    "/Sidebar/homestatistics",
    "/Sidebar/aboutslider",
    "/Sidebar/aboutservice",
    "/Sidebar/aboutservicedetails",
    "/Sidebar/aboutourteam",
    "/Sidebar/whyus",
    "/Sidebar/supportservice",
    "/Sidebar/suportSlider",
    "/Sidebar/blogservice",
    "/Sidebar/blogslider",
    "/Sidebar/supportsetailsheader",
    "/Sidebar/blogdetail",
    "/Sidebar/blogposts",
    "/Sidebar/homestatistictitle",
    "/Sidebar/restauransheader",
    "/Sidebar/restaurantservice",
    "/Sidebar/restaurantpartnerships",
    "/Sidebar/supportfaq",
    "/Sidebar/supportdetailfaq",
    "/Sidebar/aboutpartner",
    "/Sidebar/aboutbecomepartner",
    "/login",
    "/Sidebar/editor",
    "/Sidebar/posts",
    "/Sidebar/faq",
    "/Sidebar/Emurabaha",
    "/murabaxa",
    "/Drivers",
    "/Lasts",
    "/Sidebar/serviceheader",
    "/Sidebar/servicecard",
    "/Sidebar/chooseService",
    "/Sidebar/ServiceFaq",
  ];

  return (
    <div className="main-container">
      {!hideNavbarPaths.includes(location.pathname) && <Navbar />}
      <div className="flex">
        {isSidebarRoute && <Sidebar />}
        <div className={`flex-grow ${isSidebarRoute ? "pl-64" : ""}`}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<ServicesPage />} />
            {/* <Route path="/editor" element={<TextEditor />} /> */}
            {/* <Route path="/test" element={<Test />} /> */}
            <Route path="/Drivers" element={<Driverss />} />
            <Route path="/Lasts" element={<Lasts />} />
            <Route path="/service" element={<Servicess />} />
            <Route path="/murabaxa" element={<EMuraabaha />} />
            <Route path="/support" element={<Support />} />
            <Route path="/SupportDetails" element={<SupportDetails />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/details" element={<DetailsLayout />} />
            <Route path="/delivery" element={<Delivery />} />
            <Route path="/deliverymantax" element={<DeliveryManTax />} />
            <Route path="/Resturants" element={<Resturants />} />
            <Route path="/login" element={<LoginPage />} />
            {/* Protect the Sidebar routes */}
            <Route
              path="/Sidebar"
              element={<ProtectedRoute element={<Sidebar />} />}
            />
            <Route
              path="/Sidebar/homeslider"
              element={<ProtectedRoute element={<HomeSlider />} />}
            />
            <Route
              path="/Sidebar/servicecard"
              element={<ProtectedRoute element={<OurServices />} />}
            />
            <Route
              path="/Sidebar/Emurabaha"
              element={<ProtectedRoute element={<EburaabahaComponent />} />}
            />
            <Route
              path="/Sidebar/serviceheader"
              element={<ProtectedRoute element={<ServiceSlider />} />}
            />
            <Route
              path="/Sidebar/chooseService"
              element={<ProtectedRoute element={<ChooseOurService />} />}
            />
            <Route
              path="/Sidebar/ServiceFaq"
              element={<ProtectedRoute element={<ServiceFAQ />} />}
            />
            <Route
              path="/Sidebar/homeservice"
              element={<ProtectedRoute element={<HomeService />} />}
            />
            <Route
              path="/Sidebar/posts"
              element={<ProtectedRoute element={<FetchEditor />} />}
            />
            <Route
              path="/Sidebar/homeimages"
              element={<ProtectedRoute element={<HomeBecomeImage />} />}
            />
            <Route
              path="/Sidebar/homecards"
              element={<ProtectedRoute element={<HomeBecomeCard />} />}
            />
            <Route
              path="/Sidebar/faq"
              element={<ProtectedRoute element={<HomeFAQ />} />}
            />
            <Route
              path="/Sidebar/editor"
              element={<ProtectedRoute element={<TextEditor />} />}
            />
            <Route
              path="/Sidebar/homelearnmore"
              element={<ProtectedRoute element={<HomeLearnMore />} />}
            />
            <Route
              path="/Sidebar/aboutslider"
              element={<ProtectedRoute element={<AboutSlider />} />}
            />
            <Route
              path="/Sidebar/aboutpartner"
              element={<ProtectedRoute element={<PartnerShips />} />}
            />
            <Route
              path="/Sidebar/aboutservice"
              element={<ProtectedRoute element={<AboutService />} />}
            />
            <Route
              path="/Sidebar/aboutservicedetails"
              element={<ProtectedRoute element={<AboutServiceDetails />} />}
            />
            <Route
              path="/Sidebar/aboutourteam"
              element={<ProtectedRoute element={<OurTeam />} />}
            />
            <Route
              path="/Sidebar/aboutbecomepartner"
              element={<ProtectedRoute element={<BecomePartner />} />}
            />
            <Route
              path="/Sidebar/whyus"
              element={<ProtectedRoute element={<MyComponent />} />}
            />
            <Route
              path="/Sidebar/supportservice"
              element={<ProtectedRoute element={<SupportSection />} />}
            />
            <Route
              path="/Sidebar/supportfaq"
              element={<ProtectedRoute element={<SupportFAQ />} />}
            />
            <Route
              path="/Sidebar/supportdetailfaq"
              element={<ProtectedRoute element={<SupportDetailsFAQ />} />}
            />
            <Route
              path="/Sidebar/suportSlider"
              element={<ProtectedRoute element={<SupportSlider />} />}
            />
            <Route
              path="/Sidebar/blogservice"
              element={<ProtectedRoute element={<BlodService />} />}
            />
            <Route
              path="/Sidebar/blogslider"
              element={<ProtectedRoute element={<BlogSlider />} />}
            />
            <Route
              path="/Sidebar/blogdetail"
              element={<ProtectedRoute element={<BlogDetailsHeader />} />}
            />
            <Route
              path="/Sidebar/blogposts"
              element={<ProtectedRoute element={<BlogPosts />} />}
            />
            <Route
              path="/Sidebar/restauransheader"
              element={<ProtectedRoute element={<RestauransSlider />} />}
            />
            <Route
              path="/Sidebar/restaurantservice"
              element={<ProtectedRoute element={<ResturantsService />} />}
            />
            <Route
              path="/Sidebar/restaurantpartnerships"
              element={<ProtectedRoute element={<Restaurantpartnerships />} />}
            />
            <Route
              path="/Sidebar/homestatistictitle"
              element={<ProtectedRoute element={<HomeStatisticsTitle />} />}
            />
            <Route
              path="/Sidebar/homestatistics"
              element={<ProtectedRoute element={<HomeStatistics />} />}
            />
            <Route
              path="/Sidebar/supportsetailsheader"
              element={<ProtectedRoute element={<SupportDetailsHeader />} />}
            />
            {/* Add more sidebar-related routes here */}
            <Route path="*" element={<NotFound />} /> {/* Add NotFound route */}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Main;
