import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../Context/Provider";
import "../Styles/EditorStyle.css";
const BlogContent = ({ id }) => {
  const { PostsListsById, FetchPostsContents } = useContext(AppContext);
  // sessionStorage.setItem("postId", id);
  useEffect(() => {
    const getid = sessionStorage.getItem("postId");
    console.log("getid", id);
    if (getid) {
      FetchPostsContents(getid);
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  const updatedPosts = PostsListsById.map((post) => {
    let updatedHtmlContent = post.htmlContent;

    // Replace empty <img> tags with actual image URLs and dimensions
    post.images.forEach((image) => {
      const imgTag = new RegExp(
        `<img[^>]*title=["']${image.originalFileName}["'][^>]*>`,
        "g"
      );
      updatedHtmlContent = updatedHtmlContent.replace(
        imgTag,
        `<img src="${image.fileName}" title="${
          image.originalFileName
        }" width="${image.width || "auto"}" height="${image.height || "auto"}">`
      );
    });

    return {
      ...post,
      htmlContent: updatedHtmlContent,
    };
  });

  return (
    <div className="bg-white p-6 space-y-4">
      <div className="max-w-2xl mx-auto p-4 bg-white">
        {updatedPosts.map((post) => (
          <div
            key={post._id}
            className="mb-10 p-6 bg-white rounded-lg shadow-md"
          >
            <div
              className="content mb-4 leading-relaxed text-gray-800 text-base"
              style={{
                wordWrap: "break-word",
              }}
              dangerouslySetInnerHTML={{ __html: post.htmlContent }}
            />
            {/* <div className="text-xs text-gray-500">
              <p>
                Posted on: {new Date(post.createdAt).toLocaleDateString()} at{" "}
                {post.currentHour}
              </p>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogContent;
