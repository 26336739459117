import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "../images/facebook icon.png";
import InstagramIcon from "../images/instagram icon.png";
import LinkedIcon from "../images/linked.jpg";
import twitter from "../images/twitter.png";
import LazyLoad from "react-lazyload";
import { AppContext } from "../Context/Provider";

const HeroSection = () => {
  const { FetchSupportHeaders, SupportHeaderData } = useContext(AppContext);

  useEffect(() => {
    FetchSupportHeaders();
  }, []);

  return (
    <>
      {SupportHeaderData?.map((el) => (
        <section
          key={el._id}
          className="relative bg-cover bg-center bg-no-repeat mt-6"
        >
          <div
            className="relative"
            style={{
              backgroundImage: `url(${el.fileName})`,
              height: "532px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              transition: "background-image 1s ease-in-out",
            }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center">
              <div className="text-center text-white px-4 py-8 rounded-lg">
                <div className="flex justify-center mb-4">
                  <Link
                    to="#"
                    className="text-white rounded-md  flex items-center text-2xl md:text-4xl lg:text-6xl font-semibold"
                  >
                    {el.title}
                  </Link>
                </div>
                <div className="flex justify-center mb-6">
                  <p className="text-lg font-normal">
                    Connect all platforms of Rikaab to see more
                  </p>
                </div>
                <div className="flex justify-center space-x-4 items-center">
                  <Link
                    to="https://www.facebook.com/Rikaab"
                    className="text-white rounded-md  flex items-center text-2xl md:text-6xl"
                  >
                    <LazyLoad height={60} offset={100}>
                      <img
                        className="w-32 md:w-46"
                        src={FacebookIcon}
                        alt="Facebook"
                      />
                    </LazyLoad>
                  </Link>
                  <Link
                    to="https://www.instagram.com/rikaab_app?igsh=M29jZnFnb2psZmZr"
                    className="text-white rounded-md shadow-md flex items-center text-2xl md:text-6xl"
                  >
                    <LazyLoad height={60} offset={100}>
                      <img
                        className="w-32 md:w-46"
                        src={InstagramIcon}
                        alt="Instagram"
                      />
                    </LazyLoad>
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/rikaab/"
                    className="text-white rounded-md shadow-md flex items-center text-2xl md:text-6xl"
                  >
                    <LazyLoad height={60} offset={100}>
                      <img
                        className="w-32 md:w-46"
                        src={LinkedIcon}
                        alt="Gmail"
                      />
                    </LazyLoad>
                  </Link>
                  <Link
                    to="https://x.com/Rikaabapp/"
                    className="text-white rounded-md shadow-md flex items-center text-2xl md:text-6xl"
                  >
                    <LazyLoad height={60} offset={100}>
                      <img className="w-32 md:w-46" src={twitter} alt="Gmail" />
                    </LazyLoad>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
    </>
  );
};

export default HeroSection;
