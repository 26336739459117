export const FETCH_HOME_SLIDER = "FETCH_HOME_SLIDER";
export const FETCH_HOME_SERVICE = "FETCH_HOME_SERVICE";
export const FETCH_HOME_BECOME_IMAGES = "FETCH_HOME_BECOME_IMAGES";
export const FETCH_HOME_BECOME_CARDS = "FETCH_HOME_BECOME_CARDS";
export const FETCH_HOME_LEARN_MORE = "FETCH_HOME_LEARN_MORE";
export const FETCH_HOME_STATISTICS = "FETCH_HOME_STATISTICS";
export const FETCH_ABOUT_SLIDER = "FETCH_ABOUT_SLIDER";
export const FETCH_ABOUT_SERVICE = "FETCH_ABOUT_SERVICE";
export const FETCH_ABOUT_SERVICE_DRTAILS = "FETCH_ABOUT_SERVICE_DRTAILS";
export const FETCH_ABOUT_TEAMS_IMAGES = "FETCH_ABOUT_TEAMS_IMAGES";
export const FETCH_ABOUT_WHY_US = "FETCH_ABOUT_WHY_US";
export const FETCH_SUPPORT_SERVICES = "FETCH_SUPPORT_SERVICES";
export const FETCH_SUPPORT_HEADERS = "FETCH_SUPPORT_HEADERS";
export const FETCH_BLOG_SERVICES = "FETCH_BLOG_SERVICES";
export const FETCH_BLOG_SLIDER = "FETCH_BLOG_SLIDER";
export const FETCH_SUPPORT_DETAILS = "FETCH_SUPPORT_DETAILS";
export const FETCH_BLOG_DETAILS = "FETCH_BLOG_DETAILS";
export const FETCH_RECENTLY_POSTS = "FETCH_RECENTLY_POSTS";
export const FETCH_HOME_STATISTISCS_TITLES = "FETCH_HOME_STATISTISCS_TITLES";
export const FETCH_RESTAURANST_HEADERS = "FETCH_RESTAURANST_HEADERS";
export const FETCH_RESTAURANST_SERVICE = "FETCH_RESTAURANST_SERVICE";
export const SUPPORT_FAQ = "SUPPORT_FAQ";
export const SUPPORT_DETAILS_FAQ = "SUPPORT_DETAILS_FAQ";
export const SUPPORT_DETAILS_FAQ_BY_ID = "SUPPORT_DETAILS_FAQ_BY_ID";
export const RIKAAB_PARTNERS = "RIKAAB_PARTNERS";
export const BECOME_RIKAAB_PARTNERS = "BECOME_RIKAAB_PARTNERS";
export const BECOME_RIKAAB_PARTNERS_BY_ID = "BECOME_RIKAAB_PARTNERS_BY_ID";
export const FETCH_RESTAURANST_SERVICE_LOADING =
  "FETCH_RESTAURANST_SERVICE_LOADING";
export const FETCH_RESTAURANST_PARTNERSHIPS = "FETCH_RESTAURANST_PARTNERSHIPS";
export const BLOG_POST_DETAILS = "BLOG_POST_DETAILS";
export const BLOG_POST_DETAILS_BY_ID = "BLOG_POST_DETAILS_BY_ID";
export const HOME_FAQ = "  HOME_FAQ";
export const HOME_FAQ_BY_ID = "  HOME_FAQ_BY_ID";
export const FETCH_SERVICE_SLIDER = "  FETCH_SERVICE_SLIDER";
export const FETCH_SERVICE_CARD = "  FETCH_SERVICE_CARD";
export const FETCH_EMURABAHA = "  FETCH_EMURABAHA";
export const CHOOSE_US = "  CHOOSE_US";
export const SERVICE_FAQS = "  SERVICE_FAQS";
